<template>
  <authenticator :hide-sign-up="true">
    <div v-if="isUserAuthenticated" class="app" :class="appClass">
      <vue3-progress-bar />
      <app-header v-if="!appOption.appHeaderHide" />
      <app-sidebar v-if="!appOption.appSidebarHide" />
      <div class="app-content" v-bind:class="appOption.appContentClass">
        <router-view></router-view>
      </div>
      <app-footer v-if="appOption.appFooter" />
    </div>
  </authenticator>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import "@aws-amplify/ui-vue/styles.css";
import { Authenticator } from "@aws-amplify/ui-vue";
import { Amplify, Auth, Hub } from "aws-amplify";
import {
  type ProgressFinisher,
  useProgress,
} from "@marcoschulte/vue3-progress";
import { useAppOptionStore } from "@/stores/app-option";
import { useAppUserStore } from "@/stores/app-user";
import { useAppDataStore } from "@/stores/app-data";
import AppSidebar from "@/components/app/Sidebar.vue";
import AppHeader from "@/components/app/Header.vue";
import AppFooter from "@/components/app/Footer.vue";
import router from "./router";
import useApi from "@/hooks/apiV2";

Amplify.configure({
  region: "us-east-1",
  userPoolId: "us-east-1_ZvW7YVa4W",
  userPoolWebClientId: "1kcdqsukq6933dnangobajaoc7",
});

const { post } = useApi();

export default defineComponent({
  setup() {
    return {
      appOption: useAppOptionStore(),
      appUser: useAppUserStore(),
      appData: useAppDataStore(),
    };
  },
  created() {
    this.registerRouterHooks();
    this.registerAuthListeners();

    document.querySelector("body")?.classList.add("app-init");
  },
  components: {
    AppHeader,
    AppFooter,
    AppSidebar,
    Authenticator,
  },
  data() {
    return {};
  },
  computed: {
    isSandbox() {
      return import.meta.env.VITE_APP_STAGE === "stage";
    },
    isUserAuthenticated() {
      return (
        this.appUser?.user?.signInUserSession?.idToken?.jwtToken?.length > 0
      );
    },
    appClass() {
      return {
        "app-header-menu-search-toggled": this.appOption.appHeaderSearchToggled,
        "app-sidebar-minified": this.appOption.appSidebarMinified,
        "app-sidebar-collapsed": this.appOption.appSidebarCollapsed,
        "app-sidebar-mobile-toggled": this.appOption.appSidebarMobileToggled,
        "app-sidebar-mobile-closed": this.appOption.appSidebarMobileClosed,
        "app-content-full-height": this.appOption.appContentFullHeight,
        "app-content-full-width": this.appOption.appSidebarHide,
        "app-without-sidebar": this.appOption.appSidebarHide,
        "app-without-header": this.appOption.appHeaderHide,
        "app-boxed-layout": this.appOption.appBoxedLayout,
        "app-footer-fixed": this.appOption.appFooterFixed,
        sandbox: this.isSandbox,
      };
    },
  },
  methods: {
    signOutIfNotPMCEmail(user: any): void {
      const emailDomain = user?.attributes?.email
        ?.toLowerCase?.()
        ?.split?.("@")?.[1];

      if (["parkingmgt.com", "eksource.com"].indexOf(emailDomain) === -1) {
        Auth.signOut();

        alert("You are not authorized to access this application.");
      }
    },
    registerAuthListeners() {
      // check to see if the user is already logged in..
      Auth.currentAuthenticatedUser()
        .then((user) => {
          this.signOutIfNotPMCEmail(user);

          this.loadLocationData();
          this.appUser.user = user;
          this.appUser.fetchUserIdentityPermissions();
        })
        .catch(() => {
          // do nothing
        });

      // update user store on log in/out action
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signIn": {
            const user = data.payload.data;
            this.signOutIfNotPMCEmail(user);
            this.loadLocationData();
            this.appUser.user = user;
            this.appUser.fetchUserIdentityPermissions();
            break;
          }
          case "signOut": {
            this.appData.locations = [];
            this.appUser.user = false;
            break;
          }
        }
      });
    },
    registerRouterHooks() {
      const progresses = [] as ProgressFinisher[];

      router.beforeEach(async () => {
        progresses.push(useProgress().start());

        this.appOption.appSidebarMobileToggled = false;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        const targetElm = [].slice.call(
          document.querySelectorAll(".app-sidebar .menu-submenu"),
        );

        targetElm.map(function (elm) {
          elm.style.display = "";
        });
      });

      router.afterEach(async () => {
        progresses.pop()?.finish();
      });
    },
    async loadLocationData() {
      const response = await post("/getParkingLocations");
      this.appData.locations = response.data.data;
    },
  },
});
</script>
